import React from 'react'

export default function QuoteClose() {
  return (
    <svg
      className="quote quote-close"
      width="41"
      height="24"
      viewBox="0 0 41 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.568 1.51206C39.992 3.75206 39.256 6.18406 38.36 8.80806C37.464 11.3681 36.504 13.9601 35.48 16.5841C34.456 19.1441 33.4 21.6081 32.312 23.9761H22.04C22.68 21.4161 23.288 18.7601 23.864 16.0081C24.44 13.1921 24.984 10.4401 25.496 7.75206C26.008 5.06406 26.424 2.63206 26.744 0.456055H39.896L40.568 1.51206ZM18.68 1.51206C18.104 3.75206 17.368 6.18406 16.472 8.80806C15.576 11.3681 14.616 13.9601 13.592 16.5841C12.568 19.1441 11.512 21.6081 10.424 23.9761H0.151978C0.599978 22.0561 1.04798 20.0721 1.49598 18.0241C1.94398 15.9761 2.35998 13.9281 2.74398 11.8801C3.19198 9.76806 3.57598 7.75206 3.89598 5.83205C4.27998 3.91206 4.59998 2.12006 4.85598 0.456055H18.008L18.68 1.51206Z"
        fill="currentColor"
      />
    </svg>
  )
}
