import React, { useState } from 'react'
import Swiper from 'swiper'
import Slider from '../Slider'
import * as styles from './anchorNav.module.scss'

interface AccordionProps {
  data: Array<{}>
}

export default function AnchorNav(props: AccordionProps) {
  const { data } = props
  const [swiperNav, setSwiperNav] = useState<Swiper>()
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleInitSlider = (swiper: Swiper) => {
    setSwiperNav(swiper)
  }

  const onClickItem = (e: any, index: number) => {
    const target = e.target
    const linkTarget = target.getAttribute('data-target')

    setCurrentIndex(index)

    if (currentIndex > index) {
      swiperNav?.slideTo(index - 1)
    } else {
      swiperNav?.slideTo(index)
    }

    if (linkTarget) {
      const targetedSection = document.querySelector(`${linkTarget}`)

      if (targetedSection) {
        window.scrollTo({
          top:
            targetedSection.getBoundingClientRect().top + window.scrollY - 110,
          behavior: 'smooth',
        })
      }
    }
  }

  return (
    <section className={`${styles.sectionAnchorNav} section-anchor-nav`}>
      <nav className="container flex lg:justify-center items-center">
        <Slider
          onAfterInit={handleInitSlider}
          slidesPerView="auto"
          navigation={false}
          data={data
            .filter((homeBlock: any) => {
              if (homeBlock.content) {
                const blockContent = homeBlock.content
                if (blockContent.sectionTitle) {
                  return true
                }
              }

              return false
            })
            .map((homeBlock: any, index: number) => {
              const blockContent = homeBlock.content

              return {
                content: (
                  <button
                    key={`fbi-${index}`}
                    data-target={`#${blockContent.contentTypeAlias}`}
                    className={`${styles.anchorNavItem} inline-block`}
                    onClick={(e) => onClickItem(e, index)}
                  >
                    {blockContent.sectionTitle}
                  </button>
                ),
              }
            })}
        />
      </nav>
    </section>
  )
}
