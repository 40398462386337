import React from 'react'
import Swiper from 'swiper'
import { TestimonialItems } from '.'
import replaceHife from '../../../utils/replaceHife'
import Slider from '../../Core/Slider'
import Text from '../../Core/Text'
import QuoteClose from '../../Icon/QuoteClose'
import QuoteOpen from '../../Icon/QuoteOpen'
import * as styles from './testimonial.module.scss'

interface TestimonialImage {
  data: TestimonialItems[]
  onSlideChange(index: number): void
}

export default function TestimonialText(props: TestimonialImage) {
  const { data, onSlideChange } = props

  const handleChangeSlide = (e: Swiper) => {
    onSlideChange && onSlideChange(e.activeIndex)
  }

  return (
    <div className={styles.testimonialSlideTextWrap}>
      <Slider
        navigation={true}
        onSlideChange={handleChangeSlide}
        effect="fade"
        spaceBetween={0}
        data={data.map((d, index) => ({
          content: (
            <div className={styles.testimonialSlideText} key={`i-${index}`}>
              <div className={styles.testimonialQuote}>
                <QuoteOpen />
                <Text
                  tag="div"
                  as="bodyH1"
                  dangerouslySetInnerHTML={{
                    __html: replaceHife(d.content.paragraph),
                  }}
                  className="text-white testimonial"
                />
                <QuoteClose />
              </div>
              <Text tag="div" as="h6" className="text-white font-bold mb-1">
                {d.content.author}
              </Text>
              <Text
                tag="div"
                as="subtitleH3"
                className="text-white author-title mb-12"
              >
                {d.content.authorTitle}
              </Text>
            </div>
          ),
        }))}
      />
    </div>
  )
}
