import React from 'react'

export default function QuoteOpen() {
  return (
    <svg
      className="quote quote-open"
      width="41"
      height="24"
      viewBox="0 0 41 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.568 0.456055C39.992 2.95206 39.384 5.60806 38.744 8.42406C38.168 11.2401 37.624 13.9921 37.112 16.6801C36.6 19.3041 36.184 21.7361 35.864 23.9761H22.712L22.04 22.9201C22.616 20.6161 23.352 18.1841 24.248 15.6241C25.144 13.0641 26.104 10.4721 27.128 7.84806C28.216 5.22406 29.272 2.76006 30.296 0.456055H40.568ZM18.68 0.456055C18.104 2.95206 17.496 5.60806 16.856 8.42406C16.28 11.2401 15.736 13.9921 15.224 16.6801C14.712 19.3041 14.296 21.7361 13.976 23.9761H0.823978L0.151978 22.9201C0.727978 20.6161 1.46398 18.1841 2.35998 15.6241C3.25598 13.0641 4.21598 10.4721 5.23998 7.84806C6.32798 5.22406 7.38398 2.76006 8.40798 0.456055H18.68Z"
        fill="currentColor"
      />
    </svg>
  )
}
