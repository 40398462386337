import React, { useState } from 'react'
import Slider from '../../Core/Slider'
import Text from '../../Core/Text'
import GalleryItem from './GalleryItem'
import * as styles from './gallery.module.scss'
import useBreakpoint from '../../../hooks/useBreakpoint'
import { useInView } from 'react-intersection-observer'
import KLink from '../../Core/KLink'
import Modal from '../../Core/Modal'
import ModalVirtualVisit from '../ModalVirtualVisit'
import VirtualVisitButton from '../../Core/VirtualVisitButton'
import VirtualVisitIcon from '../../Icon/VirtualVisit'

interface GalleryProps {
  data: {
    contentTypeAlias: string
    titleGallery: string
    itemsGallery: []
  },
  visitVirtuelUrl?: string
}

export default function Gallery(props: GalleryProps) {
  const {
    data: { titleGallery, itemsGallery = [], contentTypeAlias },
    visitVirtuelUrl
  } = props
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  })

  const [showVirtualVisit, setShowVirtualVisit] = useState<boolean>(false)

  const breakpoint = useBreakpoint()

  const chunk = (input: any[], size: number) => {
    return input.reduce((arr, item, idx) => {
      return idx % size === 0
        ? [...arr, [item]]
        : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]]
    }, [])
  }

  return (
    <section
      ref={ref}
      id={contentTypeAlias}
      className={`${styles.gallery} ${inView ? 'in-view' : ''}`}
    >
      <div className="container">
        <Text tag="h2" as="h2" className="gallery-title">
          {titleGallery && titleGallery !== '' ? titleGallery : 'Galleries'}
        </Text>
        {visitVirtuelUrl && (
            <button className='w-8 h-8 mb-5 mt-2' onClick={()=>setShowVirtualVisit(true)}>
              <VirtualVisitIcon color="black"/>
            </button>
          )}
        
        <div className={`${styles.gallerySlide} !mt-0`}>
          <Slider
            spaceBetween={16}
            slidesPerView={1}
            data={chunk(
              itemsGallery,
              ['sm', 'md'].includes(breakpoint) ? 1 : 3
            ).map((d: any, index: number) => ({
              content: (
                <GalleryItem key={`g-${index}`} data={d} itemIndex={index} visitVirtuel={visitVirtuelUrl}/>
              ),
            }))}
          />
        </div>
      </div>
      {visitVirtuelUrl && (
        <Modal
          visible={showVirtualVisit}
          secondaryBg="902722"
          primaryBg="F5AAA2"
          onClose={() => setShowVirtualVisit(false)}
          type="modalVisit"
        >
          <ModalVirtualVisit iframeSrc={visitVirtuelUrl} />
        </Modal>
      )}
    </section>
  )
}
