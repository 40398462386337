import React, { useState } from 'react'
import * as styles from './gallery.module.scss'
import image from '../../../images/hero-kley.png'
import useBreakpoint from '../../../hooks/useBreakpoint'
import { GatsbyImage } from 'gatsby-plugin-image'
import VirtualVisit360Icon from '../../Icon/VirtualVisit360'
import ModalVirtualVisit from '../ModalVirtualVisit'
import Modal from '../../Core/Modal'

interface GalleryItemProps {
  data: Array<{
    src: string
    gatsbyImageData: any
    media: {
      alt: string
      name?: string
    }
  }>
  itemIndex: number,
  visitVirtuel?: string
}

export default function GalleryItem(props: GalleryItemProps) {
  const { data = [], itemIndex, visitVirtuel } = props

  const breakpoint = useBreakpoint()
  const [showVirtualVisit, setShowVirtualVisit] = useState<boolean>(false)

  return (
    <div
      className={`${styles.galleryItem} ${
        data.length <= 1 || ['md', 'sm'].includes(breakpoint)
          ? styles.galleryItemFull
          : ''
      }`}
    >
      {data.length > 0 && (
        <>
          <div className={`${styles.galleryItemBig} ${visitVirtuel && itemIndex == 0 ? 'relative' : ''}` } >
            {data[0] && data[0]?.gatsbyImageData ? (
                <GatsbyImage
                  image={data[0]?.gatsbyImageData}
                  alt={
                    data[0]?.media?.name && data[0]?.media?.name !== ''
                      ? data[0]?.media?.name
                      : data[0]?.media?.alt || `Kley Gallery ${itemIndex}-0`
                  }
                />
              ) : (
                <img
                  src={data[0]?.src || image}
                  alt={
                    data[0]?.media?.name && data[0]?.media?.name !== ''
                      ? data[0]?.media?.name
                      : data[0]?.media?.alt || `Kley Gallery ${itemIndex}-0`
                  }
                />
              )}
            {visitVirtuel && itemIndex == 0 && (
              <button className='mt-0 absolute top-0 right-0 p-3' onClick={()=>setShowVirtualVisit(true)}>
                <VirtualVisit360Icon/>
              </button>
            )}
          </div>
          {data.length > 1 && !['md', 'sm'].includes(breakpoint) && (
            <div className={styles.galleryItemSmall}>
              {data[1] && data[1].gatsbyImageData ? (
                <GatsbyImage
                  image={data[1]?.gatsbyImageData}
                  alt={
                    data[1]?.media?.name && data[1]?.media?.name !== ''
                      ? data[1]?.media?.name
                      : data[1]?.media?.alt || `Kley Gallery ${itemIndex}-1`
                  }
                />
              ) : (
                data[1] &&
                data[1].src && (
                  <div>
                    <img
                      src={data[1].src}
                      alt={
                        data[1]?.media?.name && data[1]?.media?.name !== ''
                          ? data[1]?.media?.name
                          : data[1]?.media?.alt || `Kley Gallery ${itemIndex}-1`
                      }
                    />
                  </div>
                )
              )}
              {data[2] && data[2].gatsbyImageData ? (
                <GatsbyImage
                  image={data[2]?.gatsbyImageData}
                  alt={
                    data[2]?.media?.name && data[2]?.media?.name !== ''
                      ? data[2]?.media?.name
                      : data[2]?.media?.alt || `Kley Gallery ${itemIndex}-2`
                  }
                />
              ) : (
                data[2] &&
                data[2].src && (
                  <div>
                    <img
                      src={data[2].src}
                      alt={
                        data[2]?.media?.name && data[2]?.media?.name !== ''
                          ? data[2]?.media?.name
                          : data[2]?.media?.alt || `Kley Gallery ${itemIndex}-2`
                      }
                    />
                  </div>
                )
              )}
            </div>
          )}
        </>
      )}
      {visitVirtuel && (
        <Modal
          visible={showVirtualVisit}
          secondaryBg="902722"
          primaryBg="F5AAA2"
          onClose={() => setShowVirtualVisit(false)}
          type="modalVisit"
        >
          <ModalVirtualVisit iframeSrc={visitVirtuel} />
        </Modal>
      )}
    </div>
  )
}
